<template>
  <div class="page-wrapper">
    <div class="box-wrapper">
      <div class="error-content py-3">
        <h1 class="error-code text-primary font-weight-normal mt-5 mb-5" style="font-size: 120px">
          <img src="/static/images/manutencao.png">
        </h1>
        <h4 class="title mb-4">Ops! Estamos em manutenção!</h4>
        <!--<p class="text-muted mb-4 font-14">Nessa sexta-feira (26/07), a partir das 16h00min (horário de Brasília), a nossa equipe irá realizar uma manutenção no sistema. Esse processo tem previsão de conclusão em 48 horas.-->
        <!--</p>-->
        <p class="text-muted mb-4 font-14">Volte mais tarde. Estamos trabalhando para melhorar sua experiência com a Royal Cargo do Brasil.
        </p>

      </div>
    </div>
  </div><!-- BEGIN: Page backdrops-->
</template>

<script>
// import store from '@/store'
export default {
  name: 'Dashboard',
  metaInfo: {
    titleTemplate: 'Estamos em manutenção %s'
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
  body {
    background-color: #eff4ff;
  }

  .box-wrapper {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .error-content {
    max-width: 540px;
    flex-basis: 540px;
    text-align: center;
  }

  .title {
    color: #877953;
  }
</style>
